import React, { ReactElement, useState } from 'react'
import { graphql } from 'gatsby'
import ReactHtmlParser from 'react-html-parser'
import Layout from '../../layouts/Layout/Layout'
import './TermsConditions.scss'
import ArticleLayout, {
  RecentArticleTypes,
} from '../../layouts/ArticleLayout/ArticleLayout'
import TERMS_AND_CONDITIONS_NAVIGATION from '../../data/terms-and-conditions-navigation.json'
import Seo from '../../components/Seo/Seo'

type TermsConditionPropTypes = {
  data: {
    termsAndConditionsData: {
      title: string
      body: string
    }
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
  }
}

const TermsConditions = ({
  data: {
    termsAndConditionsData,
    recentArticles: { nodes: recentArticles },
  },
}: TermsConditionPropTypes): ReactElement => {
  const [title] = useState(
    termsAndConditionsData.title || 'Terms and Conditions',
  )

  return (
    <Layout>
      <Seo
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/terms-and-conditions/`,
          description: termsAndConditionsData.body,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, terms, conditions, terms and conditions',
        }}
        title="Terms and Conditions"
      />
      <div className="terms-condition">
        <ArticleLayout
          asideNavItems={TERMS_AND_CONDITIONS_NAVIGATION}
          breadcrumbs={[
            {
              label: title,
            },
          ]}
          recentArticles={recentArticles}
        >
          <article className="terms-condition-article">
            <h2 className="terms-condition-article-title">{title}</h2>
            <p className="terms-condition-article-description">Legal Notice</p>
            <div className="terms-condition-article-content">
              {ReactHtmlParser(termsAndConditionsData.body)}
            </div>
          </article>
        </ArticleLayout>
      </div>
    </Layout>
  )
}

export default TermsConditions

export const pageQuery = graphql`
  query {
    termsAndConditionsData: termsAndConditions {
      ...TermsAndConditionsFields
    }
    recentArticles: allNewsAndEvents(limit: 3) {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
  }
`
